import { useEffect, useState } from 'react'
import {
	Grid,
	Input,
	Button,
	FormControl,
	Select,
	MenuItem,
	InputLabel
} from '@mui/material'
import api from '../../api/api'

const SpotUploader = props => {
	const [name, setName] = useState(null)
	const [file, setFile] = useState(null)
	const [spotType, setSpotType] = useState(null)
	const [market, setMarket] = useState(null)
	const [station, setStation] = useState(null)
	const [isValid, setIsValid] = useState(false)
	const invalid = [null, '', undefined]

	useEffect(() => {
		if (
			invalid.includes(name) ||
			invalid.includes(spotType) ||
			invalid.includes(file) ||
			invalid.includes(station)
		)
			setIsValid(false)
		else setIsValid(true)
	}, [file, spotType, name])

	const onSubmit = async () => {
		const data = new FormData()
		data.append('name', name)
		data.append('file', file)
		data.append('station', station !== '' ? station : props.station)
		data.append('market', market)
		data.append('type', spotType)
		data.append('status', 'pending')
		data.append('clientApproval', props.program.clientReview)
		data.append('programId', props.program._id)
		try {
			const res = await api.createSpot(data)
			console.log(res)

			props.setSpots([...props.spots, res])
		} catch (err) {
			throw err
		}
		Market()
	}

	const Market = () => {
		props.program.stations.forEach(station => {
			if (station.station === props.station) {
				setMarket(station.market)
			}
		})
	}
	const getStation = event => {
		props.program.stations.forEach(station => {
			if (station.station === event.target.value) {
				setStation(event.target.value)
				setMarket(station.market)
			}
		})
	}

	const usableNames = {
		':15 DJ Endorsements': 'DJ_15_Endorsements',
		':30 DJ Endorsements': 'DJ_30_Endorsements',
		':60 DJ Endorsements': 'DJ_60_Endorsements',
		':15 Brand Spot': 'Brand_15_Spot',
		':30 Brand Spot': 'Brand_30_Spot',
		':60 Brand Spot': 'Brand_60_Spot',
		'Brand Centric Promos': 'brandCentricPromos',
		'Sweepstakes Promos': 'sweepstakesPromos',
		'Appearance Promos': 'appearancePromos',
		'Call-ins': 'callins',
		'Sponsorship / Brand Mention': 'sponsorshipBrandMention',
		'N_W_TSpot': 'N_W_TSpot',
		'Vignettes': 'vignettes',
		'Interviews': 'interviews',
		'Custom': 'custom'
	}
	const { role, program } = props
	if (role !== 'client') {
		return (
			<div style={{ width: '100%' }}>
				<Grid
					container
					display='row'
					style={{ margin: 20 }}
					alignContent='center'
					justifyContent={'space-between'}
				>
					<Grid item sm={3} style={{ padding: 5 }}>
						<FormControl margin='normal' required fullWidth variant='standard'>
							<InputLabel htmlFor='client'> Spot type </InputLabel>
							<Select
								name='spotType'
								value={spotType}
								onChange={e => setSpotType(e.target.value)}
							>
								{Object.entries(usableNames).map(
									([key, val]) =>
										program[val].requiredSpot && (
											<MenuItem key={key} value={val}>
												{key}
											</MenuItem>
										)
								)}
							</Select>
						</FormControl>
					</Grid>
					<Grid item sm={3} style={{ padding: 5 }} align='flex-end'>
						<Input
							variant='standard'
							style={{ marginTop: '2em' }}
							placeholder='Spot Name'
							name='name'
							fullWidth
							value={name}
							onChange={e => setName(e.target.value)}
						/>
					</Grid>
					{role === 'tmpg' && (
						<Grid item sm={3} style={{ padding: 5 }}>
							<FormControl
								margin='normal'
								required
								fullWidth
								variant='standard'
							>
								<InputLabel htmlFor='client'> Select station </InputLabel>
								<Select
									name='station'
									value={station}
									onChange={e => getStation(e)}
								>
									{program.stations.map((value, key) => (
										<MenuItem key={key} value={value.station}>
											{value.station}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Grid>
					)}
					<Grid
						item
						sm={3}
						style={{ padding: 5 }}
						alignContent='center'
						justifyContent={'center'}
					>
						<Grid
							container
							direction='row'
							alignContent='center'
							style={{ marginTop: '2em' }}
						>
							<Grid item>
								<Button
									variant='contained'
									color='secondary'
									size='small'
									component='label'
									style={{
										paddingLeft: 10,
										paddingRight: 10,
										marginRight: '1em'
									}}
								>
									{file === null ? 'Select file' : 'Replace File'}
									<Input
										variant='standard'
										type='file'
										name='file'
										accept='audio/mpeg, audio/mp3, audio/mp4, audio/wav'
										onChange={e => setFile(e.target.files[0])}
										style={{ display: 'none' }}
									/>
								</Button>
							</Grid>
							<Grid item>
								<Button
									type='submit'
									variant='contained'
									color='primary'
									size='small'
									disabled={!isValid}
									onClick={e => onSubmit(e)}
								>
									Submit
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				{file && (
					<div
						style={{
							textAlign: 'right',
							width: '100%',
							color: 'grey',
							fontSize: '8pt'
						}}
					>
						{file.name}
					</div>
				)}
			</div>
		)
	} else {
		return ''
	}
}

export default SpotUploader
